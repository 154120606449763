import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { faIdCardAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './emergency_cotact.css';
import ToastNotifications from '../../../components/toasty-notifications/toasty-notifications';
import DeleteContactConfirmation from './delete_contact_confirmation';
import { emergencyContactValidationSchema } from '../../../services/validation_schema';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const relationOptions = [
  'Mother',
  'Father',
  'Parent',
  'Brother',
  'Sister',
  'Son',
  'Daughter',
  'Friend',
  'Spouse',
  'Partner',
  'Assistant',
  'Manager',
  'Other',
  'Housemate',
  'Doctor',
  'Emergency',
  'Family Member',
  'Guardian',
  'Social worker',
];

const EmergencyContactForm = ({
  authService,
  setEmergencyContactData,
  contactDetailsCreated,
  emergencyContact,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [emergencySwitcherValue, setEmergencySwitcherValue] = useState(emergencyContact.enabled);

  useEffect(() => {
    setEmergencySwitcherValue(emergencyContact.enabled);
  }, [emergencyContact]);

  const handleToggle = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setEmergencySwitcherValue(value);
    changeEnabled(value);
  };

  const changeEnabled = (value) => {
    const prevValue = !value;
    authService.secureAjax({
      params: {
        id: emergencyContact.id,
        enabled: value,
      },
      route: '/change_enabled',
      method: 'post',
      onSuccess() {
        setEmergencyContactData();
        ToastNotifications.successToast(`Emergency Notification has been updated`, 3000);
      },
      onFailure() {
        setEmergencySwitcherValue(prevValue);
        ToastNotifications.errorToast('', 'Sorry, something went wrong', 3000);
      },
    });
  };

  const updateEmergencyContact = (values) => {
    const { name, phoneNumber, relationship } = values;

    authService.secureAjax({
      params: {
        emergencyName: name,
        emergencyPhoneNumber: phoneNumber,
        emergencyContactRelationship: relationship,
      },
      route: '/update_emergency_contact_details_route',
      method: 'post',
      onSuccess() {
        setEmergencyContactData();
        setEmergencySwitcherValue(true);
        ToastNotifications.successToast(
          `Emergency Contact has been  ${contactDetailsCreated ? 'updated' : 'created'}`,
          3000,
        );
      },
      onFailure() {
        ToastNotifications.errorToast('', 'Sorry, something went wrong', 3000);
      },
    });
  };

  const deleteEmergencyContactConfirmation = () => {
    handleModal();
    authService.secureAjax({
      params: {
        id: emergencyContact.id,
      },
      route: '/destroy_emergency_contact_details',
      method: 'post',
      onSuccess() {
        setEmergencyContactData();
        ToastNotifications.successToast(`Emergency Contact has been deleted`, 3000);
      },
      onFailure() {
        ToastNotifications.errorToast('', 'Sorry, something went wrong', 3000);
      },
    });
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="default-form">
      {showModal && (
        <DeleteContactConfirmation
          deleteEmergencyContactConfirmation={deleteEmergencyContactConfirmation}
          handleModal={handleModal}
        />
      )}
      <Formik
        initialValues={{
          name: emergencyContact.name,
          phoneNumber: emergencyContact.phoneNumber,
          relationship: emergencyContact.relationship,
        }}
        enableReinitialize
        onSubmit={(values) => updateEmergencyContact(values)}
        validationSchema={emergencyContactValidationSchema}
      >
        {({ handleChange, handleSubmit, handleBlur, values, isValid }) => (
          <Form onSubmit={handleSubmit} className="default-form" style={{ marginTop: 35 }}>
            <div className="toggle-switch-box">
              <Form.Check
                className="custom-switch"
                type="switch"
                id="custom-switch"
                label="ACTIVE"
                checked={emergencySwitcherValue}
                onChange={handleToggle} // Simpler onChange handler
              />
            </div>

            <div className="emergency-picker-style">
              <div style={{ fontSize: 20 }}>
                <FontAwesomeIcon icon={faIdCardAlt} />
              </div>
              <select
                className="testing"
                aria-label="Default select example"
                name="relationship"
                onChange={handleChange}
                style={{
                  padding: 5,
                  border: 'none',
                  backgroundColor: 'transparent',
                  color: 'white',
                  fontSize: 20,
                  textTransform: 'capitalize',
                  fontFamily: 'Blender Pro',
                  flex: 1,
                }}
              >
                <option>{values.relationship}</option>
                {relationOptions.map((item) => {
                  if (item.toLowerCase() !== values.relationship.toLowerCase()) {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  }
                })}
              </select>
            </div>

            <Form.Group controlId="customerForm.name">
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onBlur={handleBlur('name')}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group controlId="customerForm.phone">
              <FloatingLabel
                controlId="floatingInput"
                label="Telephone number"
                className="floating-label-custom"
              >
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onBlur={handleBlur('phoneNumber')}
                  className="form-input"
                  onChange={handleChange}
                  placeholder=" "
                />
              </FloatingLabel>
            </Form.Group>

            {isValid && (
              <Button
                style={{
                  backgroundColor: '#34C759',
                  color: 'white',
                }}
                type="submit"
                disabled={!isValid}
                className="primary-button"
              >
                Update
              </Button>
            )}
          </Form>
        )}
      </Formik>

      {contactDetailsCreated && (
        <Button onClick={() => handleModal()} type="submit" className="primary-button red-variant">
          Delete Emergency Contact
        </Button>
      )}

      <p className="regular-text">
        If MotoHalo detects a serious impact our monitoring centre will be notified and you will
        receive a call. If we are unable to reach you and the event is deemed serious we will
        contact emergency services. In addition you can also add an emergency contact for us to
        notify in case of emergency
      </p>
    </div>
  );
};

export default EmergencyContactForm;
