import React, { Component } from 'react';
import './slider.css';
import Slide from 'react-reveal/Slide';

/*
 * TODO possibly change approach to one based on https://codesandbox.io/s/muddy-flower-8trt6
 * This uses css to superimpose components on each other, but use react-reveal to animate entry/exit
 * Bad hacks include adding an empty div and specifying the height, to stop the container collapsing as its
 * children are positioned absolutely. :/
 * That approach is dependency-free, but vertical spacing is fragile and components re-render in mid-transition :/
 * Usage:
 * <SlidePair renderLeft={foo} renderRight={bar}
 * where foo, bar are render functions which return components that accept props { toggleState, passedProps }
 * const foo = (props) => ( <Foo {...props}/>);
 * The rendered components Foo, Bar should have handlers that call toggleState(propsToPass) e.g. onClick to
 * switch the view to the other component, with passedProps set to propsToPass.
 */

class SlidePair extends Component {
  constructor(props) {
    super(props);
    // console.log('checking props inside slide');
    //       console.log(this.props);
    // default to left = true
    console.log('checking if my props here');
    console.log(this.props);
    const left = !(props.left === false);
    const passedProps = props.passedProps || {};
    this.state = { left, passedProps, duration: 0 };
    this.toggleState = this.toggleState.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.left !== this.props.left) {
      const left = !(this.props.left === false);
      const passedProps = this.props.passedProps || {};
      console.log(JSON.stringify({ left, passedProps }));
      this.setState({ left, passedProps });
      console.log(`updating slide pair...`);
    }
  }

  toggleState(passedProps) {
    const left = !this.state.left;
    this.setState({ left, passedProps });
  }

  render() {
    const { left, passedProps } = this.state;

    const renderArgs = { toggleState: this.toggleState, passedProps };

    const styleObject = Object.assign({ position: 'relative', height: '100%' }, this.props.style);

    return (
      <div style={styleObject}>
        <Slide left when={left} duration={this.state.duration}>
          <div
            style={{
              overflowY: 'hidden',
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: styleObject.height,
              zIndex: left ? '998' : -1,
            }}
          >
            {this.props.renderLeft(renderArgs)}
          </div>
        </Slide>

        <Slide right when={!left} duration={this.state.duration}>
          <div
            style={{
              overflowY: 'hidden',
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: styleObject.height,
              zIndex: left ? -1 : 1,
            }}
          >
            {this.props.renderRight(renderArgs)}
          </div>
        </Slide>
      </div>
    );
  }
}

export default SlidePair;
