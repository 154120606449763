import React, { Component } from 'react';
import './settings.css';
import SlidePair from '../../components/slider/slide_pair';
import CustomerDetails from './account_settings/customer_details';
import EditVehicles from './bike_settings/edit_vehicles';
// import AddBike from './bike_settings/add_bike';
import BackButton from '../../components/back_button';
import LogOutButton from '../login/logout_button';
import ManageBilling from './manage_billing.js';
import DownloadApp from './download_app/download_app';
import DeleteAccount from './delete_account/delete_account';
import EmergencyContact from './emergency_contact/emergency_contact';
import { faSignOutAlt, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notification from 'react-notify-toast';
import { BackButtonHeader } from '../../components/back_button_header';
import { Logo } from '../../components/logo';

// TODO
//  Consider instead using nested routes, e.g. https://tylermcginnis.com/react-router-nested-routes/
//  and animate transition as e.g. https://medium.com/onfido-tech/animations-with-react-router-8e97222e25e1
// -?

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePageTitle: 'Profile',
    };
    this.buttonControls = this.buttonControls.bind(this);
    this.renderButtonControls = this.renderButtonControls.bind(this);
    this.currentSettingsView = this.currentSettingsView.bind(this);
    this.settingsMain = this.settingsMain.bind(this);
    this.changeTitleName = this.changeTitleName.bind(this);
    this.setDefaultTitleName = this.setDefaultTitleName.bind(this);
  }

  changeTitleName(title) {
    this.setState({ profilePageTitle: title });
  }

  setDefaultTitleName() {
    this.setState({ profilePageTitle: 'Profile' });
  }

  buttonControls() {
    return [
      {
        name: 'Download app',
        src: '/icons/download.png',
        alt: 'download icon',
        render: () => <DownloadApp />,
      },
      {
        name: this.state.profilePageTitle,
        src: '/icons/user.png',
        alt: 'user icon',
        render: () => (
          <CustomerDetails
            authService={this.props.authService}
            changeTitleName={this.changeTitleName}
          />
        ),
      },
      {
        name: 'Emergency Contact',
        src: '/icons/emergencyContact.png',
        alt: 'emergency contact icon',
        render: () => <EmergencyContact authService={this.props.authService} />,
      },
      {
        name: 'Vehicle Settings',
        src: '/icons/requestService.png',
        alt: 'user icon',
        render: () => (
          <EditVehicles bikes={this.props.bikes} authService={this.props.authService} />
        ),
      },
      /*{
              name: 'Add New Bike',
              render: () => (<AddBike authService={this.props.authService}/>)
            },*/
      {
        name: 'Billing',
        src: '/icons/billing.png',
        alt: 'user icon',
        render: () => <ManageBilling authService={this.props.authService} />,
      },
      {
        name: 'Delete Account',
        src: '/icons/delete.png',
        alt: 'user icon',
        //name: 'Wait just a minute',
        render: () => <DeleteAccount authService={this.props.authService} />,
      },
    ];
  }

  settingsMain({ toggleState }) {
    return (
      <div className="screen">
        <div className="main-container">
          <Logo paddingBottom={15} />
          {this.renderButtonControls(toggleState)}
        </div>
      </div>
    );
  }

  renderButtonControls(toggleState) {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 20 }}>
        {this.buttonControls().map((bc, index) => (
          <button
            className=" settings_page_buttons"
            key={index}
            onClick={() => {
              toggleState({ activeComponentIndex: index });
            }}
          >
            <div className="settings-option-container">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={bc.src} alt={bc.alt} className="settings_button_icon" />

                <p
                  className="regular-text reset-margin reset-margin"
                  style={{ fontSize: 25, paddingLeft: 0 }}
                >
                  {bc.name}
                </p>
              </div>
              <FontAwesomeIcon
                color="#7C7A7A"
                style={{ fontSize: 17, textAlign: 'right', alignSelf: 'center' }}
                icon={faChevronRight}
              />
            </div>
          </button>
        ))}
        <LogOutButton authService={this.props.authService} icon={faSignOutAlt} />
      </div>
    );
  }

  specialHeaderTitle(value) {
    const title = 'ARE YOU SURE?';
    if (value === 'Delete Account') {
      return `${title} \uFE0F\u231b `;
    } else {
      return value;
    }
  }

  currentSettingsView({ toggleState, passedProps }) {
    const component = this.buttonControls()[passedProps.activeComponentIndex];
    return component ? (
      <div className="screen">
        <div className="main-container no-gap">
          <Notification options={{ position: 'relative' }} />
          <BackButtonHeader
            text={this.specialHeaderTitle(component.name)}
            onPress={() => {
              toggleState({});
              this.setDefaultTitleName();
            }}
          />
          <div className="render-container">{component.render()}</div>
        </div>
      </div>
    ) : null;
  }

  render() {
    return (
      <div className="settings-page">
        <SlidePair
          style={{ height: '100%', overflow: 'hidden' }}
          renderLeft={this.settingsMain}
          renderRight={this.currentSettingsView}
        />
      </div>
    );
  }
}

export default Settings;
